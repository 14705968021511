import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'

import Button from 'components/Button'
import CheckBox from 'components/CheckBox/CheckBox'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import Typography from 'components/Typography'

import { AppRoute, Logo, PageHeading, PaymentSystemCode } from 'assets/const'
import { useUserProfile } from 'hooks/data/useUserProfile'
import { useGetPaymentSystemsList } from 'hooks/data/usePaymentSystem'
import { usePath } from 'hooks/usePath'
import { UserService } from 'services/user.service'
import { PaymentSystemCodeType, PaymentSystemPayload } from 'types/payment-settings'

import s from './UserPaymentSettings.module.scss'

type FormValues = Record<string, boolean>;

const UserPaymentSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const errorPath = usePath(`/${AppRoute.Error}`)
  const {
    data: paymentSystemsList,
    isLoading: isPaymentSystemsListLoading,
  } = useGetPaymentSystemsList()

  const {
    data: userData,
    isLoading,
    isValidating,
    mutate: updateUser,
  } = useUserProfile()


  const initialFormValues = useMemo(() => {
    return (
      paymentSystemsList?.reduce((initialValues, system) => {
        const paymentSettings = userData?.businessSettings.paymentSettings
        const systemCode = system.code.toLowerCase();
        const available =
          paymentSettings && systemCode in paymentSettings
            ? paymentSettings[systemCode as keyof typeof paymentSettings].available
            : false
        initialValues[systemCode] = available
        initialValues[PaymentSystemCode.CW.toLocaleLowerCase()] = true
        return initialValues

      }, {} as FormValues)
    )
  }, [paymentSystemsList, userData]);

  const { register, handleSubmit } = useForm<Record<string, boolean>>({
    values: initialFormValues
  })

  const onSubmit: SubmitHandler<Record<string, boolean>> = (formData) => {
    setIsSubmitting(true)
    const { stripe, btc, eth, mango } = formData
    const payload: PaymentSystemPayload = {
      stripe: { available: stripe },
      btc: { available: btc },
      eth: { available: eth || false },
      mango: { available: mango }
    }
    UserService.setUserPaymentSettings(payload).then(() => {
      updateUser()
    })
    setIsSubmitting(false)
  }

  if ((!userData && !isLoading) || (!paymentSystemsList && !isPaymentSystemsListLoading)) {
    return <Navigate to={errorPath} />
  }

  if (isLoading || !userData || isPaymentSystemsListLoading) {
    return (
      <div style={{ position: 'relative', minHeight: '550px' }}>
        <Preloader />
      </div>
    )
  }

  return (
    <Paper>
      <PaperHeader text={PageHeading.Settings} />
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Typography as='h4' variant='h4'>
          Enable receiving payments:
        </Typography>
        <div className={s.container}>
        {initialFormValues && Object.entries(initialFormValues)?.map(([code]) => {
          const upperCaseCode = code.toLocaleUpperCase()
          return (
          <div className={s.system} key={code}>
            <div className={s.iconBox}>
              {Logo[upperCaseCode as PaymentSystemCodeType]}
            </div>
            <span className={s.name}>{upperCaseCode}</span>
            <CheckBox
              label=""
              register={register(code)}
              disabled={upperCaseCode === PaymentSystemCode.CW}
            />
          </div>
        )})}
        </div>

        <div className={s.buttonsContainer}>
          <Button
            disabled={isSubmitting || isValidating }
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
      {(isSubmitting || isValidating) && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        >
          <Preloader />
        </div>
      )}
    </Paper>
  )
}

export default UserPaymentSettings

import { FC, useState, useRef, useEffect } from 'react'
import { FaCaretDown } from 'react-icons/fa'

import s from './YearsDropdown.module.scss'

interface Props {
  options: string[]
  value: string
  onChange: (value: string) => void
}

const YearsDropdown: FC<Props> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedRef = useRef<HTMLLIElement | null>(null)

  const handleSelect = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [isOpen]);

  return (
    <div className={s.dropdown}>
      <div
        className={`${s.input} ${isOpen ? s.active : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value || 'Select an option'}
        <FaCaretDown
          fontSize={14}
          className={isOpen ? s.rotateForOpenedList : s.rotateForClosedList}
        />
      </div>
      {isOpen && (
        <ul className={s.menu}>
          {options.map((option) => (
            <li
              key={option}
              ref={option === value ? selectedRef : null}
              className={`${s.item} ${option === value ? s.selected : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default YearsDropdown

import { FC, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'

import YearsDropdown from 'components/YearsDropdown';
import TextField from 'components/TextField'

import { GoChevronRight } from 'react-icons/go';
import { GoChevronLeft } from 'react-icons/go';

import s from './DatePicker.module.scss'


interface Props {
  control: Control<any>
  name: string
  label: string
  required?: boolean
  errors?: Record<string, { message: string }>
}

const yearOptions = Array.from(
  { length: dayjs().year() - 1970 + 1 },
  (_, i) => (1970 + i).toString()
);

const DatePicker: FC<Props> = ({
  control,
  name,
  label,
  required = false,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const dayClassNames = (date: Date, value: string | null) => {
    const isSelectedDay = value ? dayjs(value).isSame(dayjs(date), 'day') : false
    const isToday = dayjs().isSame(dayjs(date), 'day')
    return `${s.day} ${isSelectedDay ? s.selectedDay : ''} ${isToday ? s.today : ''}`
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required ? 'This field is required' : false }}
      render={({ field: { onChange, value } }) => (
        <div className={s.calendarWrapper}>
          <ReactDatePicker
            selected={value ? dayjs(value).toDate() : null}
            onChange={(date: Date | null) => {
              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
              onChange(formattedDate);
            }}
            onCalendarClose={() => setIsOpen(false)}
            onCalendarOpen={() => setIsOpen(true)}
            showMonthDropdown
            showYearDropdown
            popperPlacement="bottom-start"
            popperClassName={s.customPopper}
            dayClassName={(date) => dayClassNames(date, value)}
            weekDayClassName={() => s.dayOfTheWeek}
            renderCustomHeader={({
              date,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={s.customHeader}>
                <span className={s.monthName}>{dayjs(date).format('MMMM')}</span>
                <YearsDropdown
                  options={yearOptions}
                  value={dayjs(date).year().toString()}
                  onChange={(year) => changeYear(Number(year))}
                />

                <div className={s.buttonsContainer}>
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className={s.navButton}
                    type='button'
                  >
                    <GoChevronLeft size={21}/>
                  </button>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className={s.navButton}
                    type='button'
                  >
                    <GoChevronRight size={21}/>
                  </button>
                </div>
              </div>
            )}

            customInput={
              <div>
                <TextField
                  label={label}
                  value={value ? dayjs(value).format('YYYY-MM-DD') : ''}
                  onClick={() => setIsOpen(!isOpen)}
                  error={errors?.[name]?.message}
                  wrapperClassName={s.customTextField}
                  readOnly
                />
              </div>
            }
          />
        </div>
      )}
    />
  );
};

export default DatePicker;
import {api} from 'api/api'
import {PaymentSettings, PaymentSystemPayload} from 'types/payment-settings'
import {ResponseSse} from 'types/response'
import {StripeAccount, StripeLink, UserProfile} from 'types/user-profile'
import {toastError} from 'utils/toast'
import {uuid} from 'utils/uuid'
import {useSseStore} from '../store/useSseStore';
import {UserMangoStatus} from '../types/mango';

export const UserService = {
  async getProfile(): Promise<UserProfile> {
    return api(`user/profile`).then(async (r) => {
      if (r.ok) {
        const profile = await r.json()
        const mangoState = profile?.businessSettings.paymentSettings.mango
        useSseStore.getState().setMangoUserStatus(mangoState?.payerId ? UserMangoStatus.created : UserMangoStatus.uncreated)
        return profile
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async createStripeAccountSse(
    profileData: StripeAccount
  ): Promise<ResponseSse> {
    return api(`user/stripe/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...profileData, sessionId: uuid }),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async createStripeLinkSse(linkData: StripeLink): Promise<ResponseSse> {
    return api(`user/stripe/link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...linkData, sessionId: uuid }),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async getCountriesSse(locale: { locale: string }): Promise<ResponseSse> {
    return api(`user/stripe/countries`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...locale, sessionId: uuid }),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async setUserPaymentSettings(
    settings: PaymentSystemPayload
  ): Promise<PaymentSettings> {
    return api(`user/payment-settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  // async testApiSse(data: PaymentSystem): Promise<ResponseSse> {
  //   return api(`test`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ ...data, sessionId: uuid }),
  //   })
  //     .then(async (r) => {
  //       if (r.ok) {
  //         return r.json()
  //       } else {
  //         return r.json().then((err) => Promise.reject(err))
  //       }
  //     })
  //     .catch((err) => {
  //       toastError(err)
  //     })
  // },
}

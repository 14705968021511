import useSWR from 'swr'

import { SwrKey } from 'assets/const'
import { PaymentSystems } from 'services/payment-systems.service'
import { PaymentSystem } from 'types/payment-settings'


export const useGetPaymentSystemsList = () => {
  return useSWR<PaymentSystem[], Error>(
      SwrKey.PaymentSystems,
      () => PaymentSystems.getList(),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
      }
  )
}
import { api } from 'api/api'
import { fetchApi } from 'api/fetch-retry-api'
import { baseApiUrl } from 'assets/const'
import { Configuration } from 'types/configuration'
import { StripeConfiguration } from 'types/stripe'
import { toastError } from 'utils/toast'

export const ConfigurationService = {
  async getConfiguration(): Promise<Configuration> {
    return fetchApi(`${baseApiUrl}configuration`, {
      headers: { 'Content-Type': 'application/json' },
      retries: 3,
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async getStripePublishableKey(): Promise<string>  {
    return api(`configuration/stripe`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(async (r) => {
      if (r.ok) {
        const data: StripeConfiguration = await r.json();
        return data.stripe.publishableKey;
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
      .catch((error) => {
        toastError(error)
        throw error
      })
    }
}

import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import TextField from 'components/TextField'
import AppModal from 'components/AppModal'
import Typography from 'components/Typography'
import DatePicker from 'components/DatePicker'
import CustomFormInputSelect from 'components/CustomFormInputSelect'
import Preloader from 'components/Preloader'

import { MangoService } from 'services/mango.service'
import { useSseStore } from 'store/useSseStore'
import { UserProfile } from 'types/user-profile'
import { useGetMangoCountriesSse, useGetMangoNaturalProfileSse } from 'hooks/data/useMangoApi'
import { toastError } from 'utils/toast'

import s from './EditMangoIndividualProfileModal.module.scss'


interface Props {
  onClose: () => void
  userData: UserProfile
}

type FormFields = {
  firstName: string
  lastName: string
  nationality: string
  countryOfResidence: string
  birthday: string
  email: string
  country: string | undefined
  city: string | undefined
  region: string | undefined
  postalCode: string | undefined
  addressLineFirst: string | undefined
  addressLineSecond: string | undefined
}


const EditMangoIndividualProfileModal = ({ onClose, userData }: Props) => {
  const { mangoCountries, mangoNaturalProfile: naturalProfile, setLoading, loading } = useSseStore()
  useGetMangoCountriesSse()
  useGetMangoNaturalProfileSse()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      nationality: naturalProfile?.nationality || '',
      countryOfResidence: naturalProfile?.countryOfResidence || '',
      birthday: naturalProfile?.birthday || '',
      email: userData?.email || '',
      country: naturalProfile?.address?.country || undefined,
      city: naturalProfile?.address?.city || undefined,
      region: naturalProfile?.address?.region || undefined,
      postalCode: naturalProfile?.address?.postalCode || undefined,
      addressLineFirst: naturalProfile?.address?.addressLine1 || undefined,
      addressLineSecond: naturalProfile?.address?.addressLine2 || undefined,
    },
    mode: 'onChange',
  })

  const [ addressLineFirst, addressLineSecond, postalCode, city, country, region] = watch(['addressLineFirst', 'addressLineSecond', 'postalCode', 'city', 'country', 'region']
)

  const countriesOptions = useMemo(() => {
    if (!mangoCountries) return null
    return mangoCountries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [mangoCountries])

  const onSubmit: SubmitHandler<FormFields> = (
    {birthday, nationality, countryOfResidence, addressLineFirst, addressLineSecond, city, region, postalCode, country}
  ) => {
      MangoService.editMangoNaturalProfile({
        birthday,
        nationality,
        countryOfResidence,
        address: addressLineFirst || addressLineSecond || city || region || postalCode || country ? {
          addressLine1: addressLineFirst,
          addressLine2: addressLineSecond,
          city,
          region,
          postalCode,
          country
        }
        : undefined
      })
      .then(() => (setLoading(true)))
      .catch((e) => toastError(e))
  }

  if (!countriesOptions || !naturalProfile) return <Preloader />

  return (
    <AppModal title={'Edit account'} onClose={onClose}>
      {loading && <Preloader />}
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.flexContainer}>
          <TextField
            register={register('firstName', {
              required: 'This field is required',
            })}
            label='First name*'
            autoComplete='off'
            error={errors.firstName?.message}
            disabled
          />
          <TextField
            register={register('lastName', {
              required: 'This field is required',
            })}
            label='Last name*'
            autoComplete='off'
            error={errors.lastName?.message}
            disabled
          />
        </div>
         <CustomFormInputSelect
          required
          name='nationality'
          options={countriesOptions || []}
          control={control}
          label='Nationality'
        />
        <CustomFormInputSelect
          required
          options={countriesOptions || []}
          name='countryOfResidence'
          control={control}
          label='Country of residence'
        />

        <div className={s.flexContainer}>
          <DatePicker
            control={control}
            name='birthday'
            label='Birthday'
            required
          />
          <TextField
            disabled
            register={register('email', {
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
            label='Email*'
            type='email'
            autoComplete='off'
            error={errors.email?.message}
          />
        </div>

        <div>
          <Typography variant='h4' className={s.subTitle}>Address</Typography>
          <div className={s.form}>
            <CustomFormInputSelect
              options={countriesOptions || []}
              name='country'
              control={control}
              label='Country'
              required={!!(addressLineFirst || addressLineSecond || city || region || postalCode)}
            />
            <div className={s.flexContainer}>
              <TextField
                register={register('city')}
                label='City'
                autoComplete='off'
                required={!!(addressLineFirst || addressLineSecond || country || region || postalCode)}
              />
              <TextField
                register={register('region')}
                label='Region'
                autoComplete='off'
              />
            </div>
            <TextField
              register={register('postalCode')}
              label='Postal code'
              autoComplete='off'
              required={!!(addressLineFirst || addressLineSecond || country || region || city)}
            />
            <TextField
              register={register('addressLineFirst')}
              label='Adress line 1'
              autoComplete='off'
              required={!!(postalCode || addressLineSecond || country || region || city)}
            />
            <TextField
              register={register('addressLineSecond')}
              label='Adress line 2'
              autoComplete='off'
            />
          </div>
        </div>

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={ !isValid }
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default EditMangoIndividualProfileModal

import {api} from 'api/api'
import {PaymentSystem} from 'types/payment-settings'
import {toastError} from 'utils/toast'

export const PaymentSystems = {
    async getList(): Promise<PaymentSystem[]> {
      return api(`paymentsystems`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (r) => {
          if (r.ok) {
            const data: {paymentSystems: PaymentSystem[]} = await r.json()
            return data.paymentSystems
          } else {
            return r.json().then((err) => Promise.reject(err))
          }
        })
        .catch((err) => {
          toastError(err)
        }) as Promise<PaymentSystem[]>
    },
}

import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'
import AppModal from 'components/AppModal'
import Typography from 'components/Typography'
import DatePicker from 'components/DatePicker'
import CustomSelect from 'components/CustomSelect'
import CustomTabs from 'components/CustomTabs'
import CustomFormInputSelect from 'components/CustomFormInputSelect'

import { useSseStore } from 'store/useSseStore'
import { MangoService } from 'services/mango.service'
import { companyTypesOptions } from 'assets/const'
import { UserProfile } from 'types/user-profile'
import { useGetMangoCountriesSse, useGetMangoLegalProfileSse } from 'hooks/data/useMangoApi'
import { toastError } from 'utils/toast'

import s from './EditMangoCompanyProfileModal.module.scss'

interface Props {
  onClose: () => void
  userData: UserProfile
}

type FormFields = {
  companyName: string
  companyType: string
  companyEmail: string
  companyNumber: string
  companyCountry: string
  companyCity: string
  companyRegion: string
  companyPostalCode: string
  companyAddressLineFirst: string
  companyAddressLineSecond: string

  firstName: string
  lastName: string
  nationality: string | undefined
  countryOfResidence: string | undefined
  birthday: string | undefined
  email: string | undefined
  country: string | undefined
  city: string | undefined
  region: string | undefined
  postalCode: string | undefined
  addressLineFirst: string | undefined
  addressLineSecond: string | undefined
}

const tabs = [
  { label: 'Company information', value: 0 },
  { label: 'Legal Representative', value: 1 },
]

const EditMangoCompanyProfileModal = ({ onClose, userData }: Props) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<number>(0)
  useGetMangoLegalProfileSse()
  useGetMangoCountriesSse()

  const { mangoCountries, setLoading, mangoLegalProfile: legalProfile, loading } = useSseStore()

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      companyName: legalProfile?.companyName || '',
      companyType: legalProfile?.type || '',
      companyEmail: legalProfile?.email || '',
      companyNumber: legalProfile?.companyNumber || '',
      companyCountry: legalProfile?.companyAddress?.country ?? '',
      companyCity: legalProfile?.companyAddress?.city || '',
      companyRegion: legalProfile?.companyAddress?.region || '',
      companyPostalCode: legalProfile?.companyAddress?.postalCode || '',
      companyAddressLineFirst: legalProfile?.companyAddress?.addressLine1 || '',
      companyAddressLineSecond: legalProfile?.companyAddress?.addressLine2 || '',

      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      nationality: legalProfile?.legalRepresentativeNationality,
      countryOfResidence: legalProfile?.legalRepresentativeCountryOfResidence,
      birthday: legalProfile?.legalRepresentativeBirthday,
      email: userData?.email,
      country: legalProfile?.legalRepresentativeAddress?.country ?? '',
      city: legalProfile?.legalRepresentativeAddress?.city,
      region: legalProfile?.legalRepresentativeAddress?.region,
      postalCode: legalProfile?.legalRepresentativeAddress?.postalCode,
      addressLineFirst: legalProfile?.legalRepresentativeAddress?.addressLine1,
      addressLineSecond: legalProfile?.legalRepresentativeAddress?.addressLine2,
    },
    mode: 'onChange',
  })

  const [
    city,
    region,
    postalCode,
    addressLineFirst,
    addressLineSecond,
    country
  ] = watch([
    'city',
    'region',
    'postalCode',
    'addressLineFirst',
    'addressLineSecond',
    'country'
  ])

  const validateHiddenFields = () => {
    if (activeTab === 0) {
      const values = getValues([
        'firstName',
        'lastName',
        'nationality',
        'countryOfResidence',
        'birthday',
        'email',
      ])
      const isValid = !Object.values(values).some((value) => !value)
      if (!isValid) {
        setActiveTab(1)
      }
      return isValid
    } else {
      const values = getValues([
        'companyName',
        'companyType',
        'companyEmail',
        'companyNumber',
      ])
      const isValid = !Object.values(values).some((value) => !value)
      if (!isValid) {
        setActiveTab(0)
      }
      return isValid
    }
  }

  const countriesOptions = useMemo(() => {
    if (!mangoCountries) return null
    return mangoCountries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [mangoCountries])

  const onSubmit: SubmitHandler<FormFields> = ({
    companyAddressLineFirst,
    companyAddressLineSecond,
    companyPostalCode,
    companyCountry,
    companyType,
    companyNumber,
    companyName,
    companyCity,
    companyRegion,
    companyEmail,
    birthday,
    addressLineFirst,
    addressLineSecond,
    city,
    country,
    countryOfResidence,
    region,
    postalCode,
    nationality,
  }) => {
    const isValidHidden = validateHiddenFields()
    if (isValidHidden) {
      MangoService.editMangoLegalProfile({
        companyName,
        companyNumber,
        type: companyType,
        companyAddress:
          companyCity ||
          companyRegion ||
          companyPostalCode ||
          companyAddressLineFirst ||
          companyAddressLineSecond
            ? {
                addressLine1: companyAddressLineFirst,
                addressLine2: companyAddressLineSecond,
                city: companyCity,
                region: companyRegion,
                postalCode: companyPostalCode,
                country: companyCountry,
              }
            : undefined,
        email: companyEmail,
        legalRepresentativeBirthday: birthday || '',
        legalRepresentativeAddress:
          city || region || postalCode || addressLineFirst || addressLineSecond
            ? {
                addressLine1: addressLineFirst,
                addressLine2: addressLineSecond,
                city,
                region,
                postalCode,
                country,
              }
            : undefined,
        legalRepresentativeNationality: nationality || '',
        legalRepresentativeCountryOfResidence: countryOfResidence || '',
      })
        .then(() => (setLoading(true)))
        .catch((e) => (toastError(e)))
    }
  }

  if (!countriesOptions || !legalProfile) return <Preloader />

  return (
    <AppModal
      title={t('Forms.EditMangoCompanyProfile.Title')}
      onClose={onClose}
    >
      {loading && <Preloader />}
      <CustomTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        {activeTab === 0 ? (
          <>
            <TextField
              register={register('companyName', {
                required: t('ValidationMessages.FieldRequired'),
              })}
              label={`${t('Fields.CompanyName')}*`}
              type='text'
              autoComplete='off'
              error={errors.firstName?.message}
            />
            <CustomSelect
              label={`${t('Fields.CompanyType')}*`}
              options={companyTypesOptions}
              name='companyType'
              control={control}
              required
            />

            <div className={s.flexContainer}>
              <TextField
                register={register('companyEmail', {
                  required: t('ValidationMessages.FieldRequired'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('ValidationMessages.InvalidEmail'),
                  },
                })}
                label={`${t('Fields.Email')}*`}
                type='companyEmail'
                autoComplete='off'
                error={errors.email?.message}
                disabled
              />
              <TextField
                register={register('companyNumber', {
                  required: t('ValidationMessages.FieldRequired'),
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/i,
                    message: t('ValidationMessages.OnlyLettersAndNumbers'),
                  },
                  setValueAs: (value) => value.replace(/\s+/g, ''),
                })}
                label={`${t('Fields.CompanyNumber')}*`}
                autoComplete='off'
                error={errors.email?.message}
              />
            </div>

            <div>
              <Typography variant='h4' className={s.subTitle}>
                {t('Forms.EditMangoCompanyProfile.Subtitles.CompanyAddress')}
              </Typography>
              <div className={s.form}>
                <CustomFormInputSelect
                  options={countriesOptions}
                  name='companyCountry'
                  control={control}
                  label={t('Fields.Country')}
                  required
                />
                <div className={s.flexContainer}>
                  <TextField
                    register={register('companyCity', {
                      required: t('ValidationMessages.FieldRequired'),
                    })}
                    label={`${t('Fields.City')}*`}
                    error={errors.companyCity?.message}
                    required
                  />
                  <TextField
                    register={register('companyRegion')}
                    label={t('Fields.Region')}
                  />
                </div>
                <TextField
                  register={register('companyPostalCode', {
                    required: t('ValidationMessages.FieldRequired'),
                  })}
                  label={`${t('Fields.PostalCode')}*`}
                  error={errors.companyPostalCode?.message}
                />
                <TextField
                  register={register('companyAddressLineFirst', {
                    required: t('ValidationMessages.FieldRequired'),
                  })}
                  label={`${t('Fields.AddressLine')}1*`}
                  error={errors.companyAddressLineFirst?.message}
                />
                <TextField
                  register={register('companyAddressLineSecond')}
                  label={`${t('Fields.AddressLine')} 2`}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={s.flexContainer}>
              <TextField
                register={register('firstName', {
                  required: t('ValidationMessages.FieldRequired'),
                })}
                label={`${t('Fields.FirstName')}*`}
                type='text'
                autoComplete='off'
                error={errors.firstName?.message}
                disabled
              />
              <TextField
                register={register('lastName', {
                  required: t('ValidationMessages.FieldRequired'),
                })}
                label={`${t('Fields.LastName')}*`}
                type='text'
                autoComplete='off'
                error={errors.lastName?.message}
                disabled
              />
            </div>
            <CustomFormInputSelect
              required
              options={countriesOptions || []}
              name='nationality'
              control={control}
              label={t('Fields.Nationality')}
            />
            <CustomFormInputSelect
              required
              options={countriesOptions}
              name='countryOfResidence'
              control={control}
              label={t('Fields.CountryOfResidence')}
            />

            <div className={s.flexContainer}>
              <DatePicker
                control={control}
                name='birthday'
                label={`${t('Fields.Birthday')}*`}
                required
              />
              <TextField
                register={register('email', {
                  required: t('ValidationMessages.FieldRequired'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('ValidationMessages.InvalidEmail'),
                  },
                })}
                label={`${t('Fields.Email')}*`}
                type='email'
                autoComplete='off'
                error={errors.email?.message}
                disabled
              />
            </div>

            <div>
              <Typography variant='h4' className={s.subTitle}>
                {t('Forms.EditMangoCompanyProfile.Subtitles.Address')}
              </Typography>
              <div className={s.form}>
                <CustomFormInputSelect
                  options={countriesOptions}
                  name='country'
                  control={control}
                  label={t('Fields.Country')}
                  required={
                    !!(
                      city ||
                      region ||
                      postalCode ||
                      addressLineFirst ||
                      addressLineSecond
                    )
                  }
                />
                <div className={s.flexContainer}>
                  <TextField
                    register={register('city')}
                    label={t('Fields.City')}
                    required={!!(addressLineFirst || addressLineSecond || country || region || postalCode)}
                  />
                  <TextField register={register('region')} label={t('Fields.Region')} />
                </div>
                <TextField
                  register={register('postalCode')}
                  label={t('Fields.PostalCode')}
                  required={!!(addressLineFirst || addressLineSecond || country || region || city)}
                />
                <TextField
                  register={register('addressLineFirst')}
                  label={`${t('Fields.AddressLine')} 1`}
                  required={!!(postalCode || addressLineSecond || country || region || city)}
                />
                <TextField
                  register={register('addressLineSecond')}
                  label={`${t('Fields.AddressLine')} 2`}
                />
              </div>
            </div>
          </>
        )}

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            {t('Buttons.Cancel')}
          </Button>
          <Button type='submit' fullWidth disabled={!isValid}>
            {t('Buttons.Save')}
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default EditMangoCompanyProfileModal
